import moment from "moment";
import { momentLocalizer, Views } from "react-big-calendar";
import AppointmentEvent from "./Appointment";

const localizer = momentLocalizer(moment);

const components: any = {
  event: ({ event }: any) => {
    const data = event?.data;
    if (data?.appointment)
      return <AppointmentEvent appointment={data?.appointment} />;
    return null;
  },
};

export const generateCalendarProps = (date: string) => {
  return {
    components,
    localizer,
    defaultDate: moment(date).toDate(),
    defaultView: Views.DAY,
    view: Views.DAY,
    min: moment(date).hours(8).toDate(),
    max: moment(date).hours(19).toDate(),
  };
};
